import React, { ReactElement } from 'react';
import TiltCenterCard from '../util/tiltCenterCard';
import GenericDevice from './step2Device/generic';
import DeltaDoreDevice, { deltaDoreDeviceStepValidation } from './step2Device/deltaDore';
import TuyaDevice, { tuyaDeviceValidation } from './step2Device/tuya';
import Registration1 from '../stepper/registration1';
import Registration2 from '../stepper/registration2';
import ProviderLogo from '../util/providerLogo';
import Background from '../util/background';

interface Customization {
  registration1: React.ReactNode;
  registration2: React.ReactNode;
  layout: (component: React.ReactNode) => React.ReactNode;
}

export function customizeByProvider(providerSlug: string, serverUrl: string): Customization {
  let providerName: string;
  let registration1: React.ReactNode;
  let registration2: React.ReactNode;
  let backgroundStyle: object;
  let logoUrl: string;
  let logo: ReactElement;
  let poweredBy: boolean;

  // Default grey background
  backgroundStyle = { backgroundColor: '#F6F6F6' };

  const tiltLogoUrl = '/Tilt-logo-blue-white-background.png';

  switch (providerSlug) {
    case 'generic':
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="190px" marginTop="10px" marginBottom="0px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={tiltLogoUrl}
          providerSlug={providerSlug}
          providerName={'Tilt Energy'}
          providerDomain={'tilt-energy.com'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={'generic_provider'}
          includePRM={false}
          step2Device={<GenericDevice />}
          pilotage_system={null}
          firebase_user_access={[]}
        />
      );
      poweredBy = true;
      break;
    case 'delta-dore':
      providerName = 'Delta Dore';
      logoUrl = `/providers/${providerSlug}/logo.svg`;
      logo = <ProviderLogo logoSrc={logoUrl} height="110px" marginTop="60px" marginBottom="30px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={`/providers/${providerSlug}/logo.png`}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'deltadore.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={false}
          step2Device={<DeltaDoreDevice validationSchema={deltaDoreDeviceStepValidation} />}
          pilotage_system={'Delta Dore'}
          firebase_user_access={['ZeIIbBkChngnqgnSSUHqgC6Seai1']}
        />
      );
      poweredBy = false;
      break;
    case 'bioenergies':
      providerName = 'bioenergies';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="150px" marginTop="40px" marginBottom="20px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'bioenergies31.com'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={false}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={false}
          step2Device={null}
          pilotage_system={null}
          firebase_user_access={['G4Jtl6ioKidguO2Mmm90a0JP5O33']}
        />
      );
      poweredBy = false;
      break;
    case 'premium':
      providerName = 'Premium';
      backgroundStyle = { background: `linear-gradient(#53e3eedd, #23efaadd)` };
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="75px" marginTop="60px" marginBottom="30px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={`/providers/${providerSlug}/logo-green.png`}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'premiumenergyfrance.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['zzoHOjXDbTY4bvezy3xoXBnhNkz2']}
        />
      );
      poweredBy = false;
      break;
    case 'dhomeco':
      providerName = 'Dhomeco';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="49px" marginTop="60px" marginBottom="30px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'dhomeco.fr'}
          supportEmail={'info@dhomeco.fr'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['np6m2lNyjOTmGmnzVz6VTlIWjfl1']}
        />
      );
      poweredBy = false;
      break;
    case 'homelior':
      providerName = 'Homélior';
      logoUrl = `/providers/${providerSlug}/logo.webp`;
      logo = <ProviderLogo logoSrc={logoUrl} height="55px" marginTop="60px" marginBottom="17px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'homelior.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['GjXNbcAX1tVSx55lclUuHTEBKCH2']}
        />
      );
      poweredBy = false;
      break;
    case 'profire':
      providerName = 'Profire';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="105px" marginTop="60px" marginBottom="12px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'groupeprofire.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['PhFsr5qTMvfSxtROZGCpCTio29L2']}
        />
      );
      poweredBy = false;
      break;
    case 'renovhabitatandco':
      providerName = "Rénov' Habitat & Co";
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="145px" marginTop="35px" marginBottom="5px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'renovhabitatandco.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['xkOPgq8YpLS2rjvejSysa8PQCR83']}
        />
      );
      poweredBy = false;
      break;
    case 's2ee':
      providerName = 'S2EE';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="105px" marginTop="50px" marginBottom="20px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'s2ee.fr'}
          supportEmail={'comptabilite@s2ee.fr'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['aCtL59xFfdPBCwMqpLbRV634cTB3']}
        />
      );
      poweredBy = false;
      break;
    case 'effy':
      providerName = 'Effy';
      logo = (
        <ProviderLogo
          logoSrc={`/providers/${providerSlug}/logo.svg`}
          height="65px"
          marginTop="50px"
          marginBottom="10px"
        />
      );
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={`/providers/${providerSlug}/logo.png`}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'effy.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['dCq33f6NjYbXGrysIzCH4gLQGVh2']}
        />
      );
      poweredBy = false;
      break;
    case 'primes-energie':
      providerName = 'Primes Energie';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="110px" marginTop="50px" marginBottom="3px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'primesenergie.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['HtwZuNqBuZSU6nF1I5UpQpAh5uz2']}
        />
      );
      poweredBy = false;
      break;
    case 'stockdis':
      providerName = 'STOCKDIS';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="105px" marginTop="50px" marginBottom="11px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'tilt-energy.com'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['6TXZvPIxRGbDSutdq6NgiVGIaTc2']}
        />
      );
      poweredBy = false;
      break;
    case 'saviva-energie':
      providerName = 'Saviva Energie';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="150px" marginTop="40px" marginBottom="11px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'savivaenergie.fr'}
          supportEmail={'contact@savivaenergie.fr'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['QIS5tDFN3Nc4dJEfSgKUvclBZ9z2']}
        />
      );
      poweredBy = false;
      break;
    case 'egn-renovation':
      providerName = 'Egn Renovation';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="150px" marginTop="40px" marginBottom="3px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'tilt-energy.com'}
          supportEmail={'egnrenovation@gmail.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['CQ1zyz37MSSLFI2qV2nkLGrXuF43']}
        />
      );
      poweredBy = false;
      break;
    case 'bittons-compagnie':
      providerName = 'Bittons Compagnie';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="150px" marginTop="40px" marginBottom="3px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'tilt-energy.com'}
          supportEmail={'bitton.compagnie@gmail.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['TULx3tIjaier3dwr7uBaJYoCxfL2']}
        />
      );
      poweredBy = false;
      break;
    case 'ecologie-collective':
      providerName = 'Écologie Collective';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="80px" marginTop="57px" marginBottom="15px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'ecologiecollective.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['3NReqoy0YagLs0Vggm5gj2X8ydB3']}
        />
      );
      poweredBy = false;
      break;
    case 'patrimoine-energie':
      providerName = 'PPF Énergie';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="75px" marginTop="57px" marginBottom="15px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'patrimoine-energie.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['76s8TPRN9Ba7OctK8auqYJoRBWh2']}
        />
      );
      poweredBy = false;
      break;
    case 'ecofrance-2d':
      providerName = 'Eco France 2D';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="95px" marginTop="57px" marginBottom="10px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'ecofrance-2d.fr'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['HkmmvqTHcyMuyEUlk9bi3Vxrfu23']}
        />
      );
      poweredBy = false;
      break;
    case 'adn-energies':
      providerName = 'ADN ENERGIES';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="135px" marginTop="50px" marginBottom="2px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'adn-energies.fr'}
          supportEmail={'contact@adn-energies.fr'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['bEmvth1D1Aflpu3qwfPKsG7B5B62']}
        />
      );
      poweredBy = false;
      break;
    case 'kilowatt-energy':
      providerName = 'KILOWATT ENERGY';
      logoUrl = `/providers/${providerSlug}/logo.png`;
      logo = <ProviderLogo logoSrc={logoUrl} height="130px" marginTop="50px" marginBottom="2px" />;
      registration1 = (
        <Registration1
          serverUrl={serverUrl}
          emailLogoSrc={logoUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          providerDomain={'tilt-energy.com'}
          supportEmail={'support@tilt-energy.com'}
          includeDevice={true}
        />
      );
      registration2 = (
        <Registration2
          serverUrl={serverUrl}
          providerSlug={providerSlug}
          providerName={providerName}
          includePRM={true}
          step2Device={<TuyaDevice validationSchema={tuyaDeviceValidation} />}
          pilotage_system={'Tuya'}
          firebase_user_access={['QgbBOGobHaXB0qetouBZJVwf1Wn2']}
        />
      );
      poweredBy = false;
      break;
    default:
      logo = <ProviderLogo logoSrc={null} height="49px" marginTop="80px" marginBottom="30px" />;
      registration1 = <div style={{ textAlign: 'center' }}>Error 404</div>;
      registration2 = <div style={{ textAlign: 'center' }}>Error 404</div>;
  }

  const layout = (component: React.ReactNode): React.ReactNode => (
    <>
      <Background backgroundStyle={backgroundStyle} />
      <TiltCenterCard logoChild={logo} poweredBy={poweredBy}>
        {component}
      </TiltCenterCard>
    </>
  );

  return { registration1, registration2, layout };
}
